<template>
  <TopNav v-if="!isAccountCreated" />
  <Slider v-if="!isAccountCreated" />
  <FeaturedGames v-if="!isAccountCreated" />
  <GamesFilter :selectedFilter="selectedTab" @update-hours="handleHoursUpdate" @update-days="handleDayUpdate" @update-live="handleLive"
    @update-country="handleCountryUpdate" @filter-selected="handleFilterSelected" @update-date="handleDateUpdate" />
  <SportsbookFilters @filter-tournament="setTournamentId" @filter-market="setHighlightMarketId" @filter-selected="handleFilterSelection"/>

  <div class="sportsbook-wrapper">
    <div class="container">
      <div class="row min-height-all">
        <div v-if="selectedFilter === 'tournament'" class="col-12" ref="scrollContainer">
          <div v-if="isBusy">
            <Shimmer />
          </div>

<!--          <div v-else-if="!tours && tours.length === 0">-->
<!--            <div class="no-data text-center p-3">-->
<!--              <h1><i class="bi bi-exclamation-triangle-fill"></i></h1>-->
<!--              <p> &nbsp; There are no games available for this selection</p>-->
<!--            </div>-->
<!--          </div>-->
          <div class="sportsbook-wrapper">
            <div class="games-wrapper">
              <div v-if="highlights.length > 0">
                <div v-for="game in highlights" :key="game.match_id" class="game-playing">
                  <div class="card">
                    <div class="match-odds-ligi">
                      {{ game.country }} / {{ game.tournament }}
                    </div>
                    <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'), game_id: game.game_id } }"
                                 :aria-label="`View more markets for ${game.tournament} in ${game.country} starting at ${getStartTimeDisplay(game.date)}`">
                      <div class="leaguetype-and-time d-none">
                                <span class="country-league-wrap">
                                  <span class="country-league">{{ game.country }}</span>
                                  <span class="leaguetype-wrap">{{ game.tournament }}</span>
                                </span>
                        <span :class="{'red-text': isStartingSoon(game.date)}" class="date-playing-and-time-playing">
                                  {{ getStartTimeDisplay(game.date) }}
                                </span>
                      </div>
                    </router-link>
                    <div class="teams-playing-and-market-selection">

                      <!-- Teams-->
                      <div class="teams-playing-wrap">
                        <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                                     class="team-playing">
                          <span v-show="game.match_status !== 'Not started'" class="scores text-danger">{{ game.home_score }}</span>
                          {{ game.home_team.length > 15 ? game.home_team.slice(0, 15) + '...' : game.home_team }}
                        </router-link>
                        <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                                     class="team-playing">
                          <span v-show="game.match_status !== 'Not started'" class="scores text-danger">{{ game.away_score }}</span>
                          {{ game.away_team.length > 15 ? game.away_team.slice(0, 15) + '...' : game.away_team }}
                        </router-link>
                      </div>
                      <!--                              <div class="market-graph"-->
                      <!--                                   @click="openLinkInNewTab('https://s5.sir.sportradar.com/maybetsmts/en/match/' + game.match_id)">-->
                      <!--                                <i class="bi bi-bar-chart"></i>-->
                      <!--                              </div>-->

                      <!--  Odds-->
                      <div class="market-selection-wrap">
                        <div class="market-selection" v-for="outcome in game.highlight_market.outcomes"
                             :key="outcome.outcome_id">
                                  <span :class="['small', 'select-market', getTeamClass(outcome.alias)]">
                                    <Odd v-bind:date="game.date" v-bind:match_id="game.match_id"
                                         v-bind:market_name="game.highlight_market.market_name"
                                         v-bind:market_id="game.highlight_market.market_id"
                                         v-bind:market_status="game.highlight_market.status"
                                         v-bind:specifier="game.highlight_market.specifier"
                                         v-bind:home_team="game.home_team" v-bind:away_team="game.away_team"
                                         v-bind:pick="outcome" v-bind:producer_id="game.producer_id"
                                         v-bind:producer_status="game.producer_status" v-bind:status="game.status"
                                         v-bind:status_name="game.highlight_market.status_name"
                                         v-bind:game_id="game.game_id"
                                         v-bind:category_id="game.category_id"
                                         v-bind:country="game.country"
                                         v-bind:tournament_id="game.tournament_id"
                                         v-bind:tournament="game.tournament"
                                    />
                                  </span>
                        </div>
                      </div>
                    </div>
                    <div class="gameid-and-time">
                      <div class="date-and-time-playing">
                        <span :class="{'red-text': isStartingSoon(game.date)}" v-show="game.match_status === 'Not started'" class="date-playing-and-time-playing">
                          {{ getStartTimeDisplay(game.date) }}&nbsp;
                        </span>
                        <span v-show="game.match_status !== 'Not started'" class="date-playing-and-time-playing red-text">
                          {{ game.event_time }}&nbsp;
                        </span>
                        <span v-show="game.match_status !== 'Not started'">
<!--                          <i class="blink bi-camera-video-fill text-danger"></i>&nbsp;-->
                          <span class="text-danger">{{ game.match_status }}</span>&nbsp;
                        </span>
                        <span class="game-id-wrap">&nbsp;&nbsp;&nbsp; ID: #<span class="game-id">{{ game.game_id }}</span>
                                </span>
                      </div>
                      <router-link
                        :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                        class="other-markets-options"
                        :class="{ 'selected-odd': isSelectedOdd(game.match_id) }"
                        :aria-label="`View ${game.total_markets} more markets for this match`">
                        +{{ game.total_markets }} Markets
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center no-data-message" v-else-if="highlights.length === 0 && hasFetched">
                <div class="no-data-icon">
                  <i class="bi bi-exclamation-circle" style="font-size: 48px; color: grey;"></i>
                </div>
                <div class="no-markets-message">
                  There are currently no games available for this selection.<br><br>
                  Meanwhile, enjoy these other exciting products!
                  <div  class="no-markets-message-wrapper">
                    <div class="button-group">
                      <button class="btn notif-btn" @click="handleAviator"><i class="bi bi-airplane"></i> &nbsp; Aviator</button>
                      <button class="btn notif-btn" @click="handleLigi"><i class="bi bi-play-btn"></i> &nbsp; LigiSoo</button>
                      <button class="btn notif-btn" @click="handleCasino"><i class="bi bi-suit-club"></i> &nbsp; Casino</button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="no-data">
                  <Shimmer />
                </div>
              </div>
            </div>
          </div>




          <InfiniteScroll :onLoad="handleScroll" :enabled="!isLoadingMore">
            <template v-if="isLoadingMore">
              <div class="bottom-loader">
                <Shimmer />
              </div>
            </template>
          </InfiniteScroll>
          <ContentWrap :currentSport="currentSport" />
        </div>
        <div v-if="selectedFilter === 'time'" class="col-12" ref="scrollContainer">
          <div v-if="isBusy">
            <Shimmer />
          </div>

          <div v-else-if="filteredHighlights.length > 0" class="accordion" id="gamesAccordion">
            <div class="accordion-item" v-for="country in filteredHighlights" :key="country.hour">
              <h2 class="accordion-header" :id="'headingOne' + country.hour">
                <button
                  class="accordion-button"
                  style="background-color: var(--color-xg); color: var(--color-hg); border: 1px solid var(--border-bg-color)"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapseOne' + country.hour"
                  :aria-controls="'collapseOne' + country.hour"
                  :aria-label="`Expand ${country.hour + ':00'} O'Clock, containing ${country.games.length} games`"
                  :aria-expanded="expandedLeagues[country.hour] || false"
                  @click="toggleExpansion(country.hour)"
                >
                  <span class="accordion-header">
                    <span class="countryname">{{ country.hour + ' : 00' }}  {{'('+ country.games.length + ')'}}</span>
                  </span>
                  <span class="league-arrow">
                    <i :class="expandedLeagues[country.hour] ? 'bi bi-chevron-right' : 'bi bi-chevron-down'"></i>
                  </span>
                </button>
              </h2>
              <div :id="'collapseOne' + country.hour" class="accordion-collapse collapse show"
                   :aria-labelledby="'headingOne' + country.hour" data-bs-parent="#gamesAccordion">
                <div class="accordion-body">
                  <div class="sportsbook-wrapper">
                    <div class="games-wrapper">
                      <div v-if="isBusy">
                        <Shimmer />
                      </div>
                      <div v-else-if="country.games.length > 0">
                        <div v-for="game in country.games" :key="game.match_id" class="game-playing">
                          <div class="card">
                            <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }">
                              <div class="leaguetype-and-time">
                                <span class="country-league-wrap">
                                  <span class="country-league">{{ game.country }} |</span>
                                  <span class="leaguetype-wrap">{{ game.tournament }}</span>
                                </span>
<!--                                <span class="date-playing-and-time-playing">-->
<!--                                  {{ getStartTimeDisplay(game.date) }}-->
<!--                                </span>-->
                              </div>
                            </router-link>
                            <div class="teams-playing-and-market-selection">
                              <div class="teams-playing-wrap">
                                <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                                             class="team-playing">
                                  <span v-show="game.match_status !== 'Not started'" class="scores text-danger">{{ game.home_score }}</span>
                                  {{ game.home_team.length > 15 ? game.home_team.slice(0, 15) + '...' : game.home_team }}
                                </router-link>
                                <router-link :to="{ name: 'more-markets-event', params: { country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                                             class="team-playing">
                                  <span v-show="game.match_status !== 'Not started'" class="scores text-danger">{{ game.away_score }}</span>
                                  {{ game.away_team.length > 15 ? game.away_team.slice(0, 15) + '...' : game.away_team }}
                                </router-link>
                              </div>
<!--                              <div class="market-graph"-->
<!--                                   @click="openLinkInNewTab('https://s5.sir.sportradar.com/maybetsmts/en/match/' + game.match_id)">-->
<!--                                <i class="bi bi-bar-chart"></i>-->
<!--                              </div>-->
                              <div class="market-selection-wrap">
                                <div class="market-selection" v-for="outcome in game.highlight_market.outcomes"
                                     :key="outcome.outcome_id">
                                  <span :class="['small', 'select-market', getTeamClass(outcome.alias)]">
                                    <Odd v-bind:date="game.date" v-bind:match_id="game.match_id"
                                         v-bind:market_name="game.highlight_market.market_name"
                                         v-bind:market_id="game.highlight_market.market_id"
                                         v-bind:market_status="game.highlight_market.status"
                                         v-bind:specifier="game.highlight_market.specifier"
                                         v-bind:home_team="game.home_team" v-bind:away_team="game.away_team"
                                         v-bind:pick="outcome" v-bind:producer_id="game.producer_id"
                                         v-bind:producer_status="game.producer_status" v-bind:status="game.status"
                                         v-bind:status_name="game.highlight_market.status_name"
                                         v-bind:game_id="game.game_id"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="gameid-and-time">
                              <div class="date-and-time-playing">
                                <span :class="{'red-text': isStartingSoon(game.date)}" v-if="game.match_status === 'Not started'" class="date-playing-and-time-playing">
                                  {{ getStartTimeDisplay(game.date) }}
                                </span>&nbsp;
                                <span :class="{'red-text': isStartingSoon(game.date)}" v-else-if="game.match_status !== 'Not started'" class="date-playing-and-time-playing">
                                  {{ formatDateTime(game.date) }}
                                </span>&nbsp;
                                <span v-show="game.match_status !== 'Not started'">
<!--                                  <i class="blink bi-camera-video-fill text-danger"></i>&nbsp;-->
                                  <span class="text-danger">{{ game.match_status }}</span>&nbsp;
                                </span>
                                <span class="game-id-wrap">&nbsp;&nbsp; ID: #<span class="game-id">{{ game.game_id }}</span>
                                </span>
                              </div>
                              <router-link
                                :to="{ name: 'more-markets-event', params: {  country: game.country.replace(/\s+/g, '_'), tournament: game.tournament.replace(/\s+/g, '_'), event_name: game.name.replace(/(\s+|\.)/g, match => match === '.' ? '' : '_'),game_id: game.game_id } }"
                                class="other-markets-options"
                                :class="{ 'selected-odd': isSelectedOdd(game.match_id) }"
                                :aria-label="`View ${game.total_markets} more markets for this match`">
                                +{{ game.total_markets }} Markets
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="filteredHighlights === null && hasFetched" class="no-markets-message-wrapper">
            <div class="no-data-icon">
              <i class="bi bi-exclamation-circle" style="font-size: 48px; color: grey;"></i>
            </div>
            <div class="no-markets-message">
              There are currently no games available for this selection.<br><br>
              Meanwhile, enjoy these other exciting products!
              <div  class="no-markets-message-wrapper">
                <div class="button-group">
                  <button class="btn notif-btn" @click="handleAviator"><i class="bi bi-airplane"></i> &nbsp; Aviator</button>
                  <button class="btn notif-btn" @click="handleLigi"><i class="bi bi-play-btn"></i> &nbsp; LigiSoo</button>
                  <button class="btn notif-btn" @click="handleCasino"><i class="bi bi-suit-club"></i> &nbsp; Casino</button>
                </div>
              </div>
            </div>
          </div>

          <InfiniteScroll :onLoad="handleScroll" :enabled="!isLoadingMore">
            <template v-if="isLoadingMore">
              <div class="bottom-loader">
                <Shimmer />
              </div>
            </template>
          </InfiniteScroll>
          <ContentWrap :currentSport="currentSport" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, defineAsyncComponent } from 'vue'
import { useSportStore } from '@/stores/sport.js'
import dayjs from 'dayjs'
import { useTabStore } from '@/stores/tab.js'
import fix from '@/services/fix.js'
import client from '@/services/mqttService.js'
import _ from 'lodash'
import { useAuthStore } from '@/stores/auth.js'
import { useFixtureStore } from '@/stores/fixture.js'
import { useHead } from '@vueuse/head'
import router from '@/router/index.js'
import InfiniteScroll from "@/components/InfiniteScroll.vue";
import { onBeforeRouteLeave } from "vue-router";

const TopNav = defineAsyncComponent(() => import('@/components/Nav/TopNav.vue'))
const Slider = defineAsyncComponent(() => import('@/components/Slider.vue'))
const Filter = defineAsyncComponent(() => import('@/components/Filter.vue'))
const Markets = defineAsyncComponent(() => import('@/components/Markets.vue'))
const Shimmer = defineAsyncComponent(() => import('@/components/shimmer/Shimmer.vue'))
const Odd = defineAsyncComponent(() => import('@/components/Odd.vue'))
const FeaturedGames = defineAsyncComponent(() => import('@/components/Nav/FeaturedGamesNav.vue'))
const ContentWrap = defineAsyncComponent(()=>import('@/components/content/ContentWrap.vue'))
const GamesFilter = defineAsyncComponent(() => import('@/components/GamesFiilter.vue'))
const SportsbookFilters = defineAsyncComponent(() => import('@/components/SportsbookFilters.vue'))
const isMounted = ref(false);
export default {
  name: 'Home',
  props: ['sport_id', 'sport_name'],
  components: {
    SportsbookFilters,
    GamesFilter,
    Odd,
    Filter,
    Shimmer,
    Markets,
    Slider,
    TopNav,
    FeaturedGames,
    ContentWrap,
    InfiniteScroll,
  },
  setup() {
    useHead({
      title: 'Maybets: Best Betting Platform for Sports & More',
      meta: [
        { name: 'description', content: 'Bet on sports, virtuals, and games with Maybets for big wins and top prizes. Enjoy the best odds on every game.' },
        { name: 'keywords', content: 'Maybets, Betting App, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Download App, Bonuses, Instant Notifications' },
        { rel: 'canonical', href: 'https://maybets.com' }
      ]
    });
    onBeforeRouteLeave(() => {
      sessionStorage.setItem('scrollPosition', window.scrollY);
    });
    return {};
  },
  data() {
    return {
      highlights: [],
      loading: false,
      hasFetched: false,
      error: null,
      hours: 0,
      matchID: 0,
      producer_status: 0,
      showDatepicker: false,
      isBusy: false,
      isLoadingMore: false,
      currentPage: 0,
      // loaderDistance: '',
      lastPage: 0,
      isPopular: true,
      date: null,
      tourNamentId: null,
      categoryId: null,
      highlightMarketId: null,
      scroll2Top: false,
      matchIds: new Set(),
      filteredHighlights: [],
      tours: [],
      optionFilter: null,
      defaultParams: {
        page: 1,
        per_page: 10,
        match_live_status: 0,
        highlight_market_id: 0,
        tournament_id: 0,
        category_id: 0,
        daily: 0,
        hours: 0,
        today: 0,
        tomorrow: 0,
        upcoming: 0,
        boosted: 0,
        start: '',
        end: '',
        order_type: ''
      },
      params: {},
      selectedFilter: 'tournament',
      debounceTimeout: 200,
      debounceTimer: null,
      resetHighlights: false,
      currentSport: '',
      // isExpanded: true,
      expandedLeagues: {}
    }
  },
  methods: {
    openLinkInNewTab(url) {
      window.open(url, '_blank')
    },

    handleJackpot() {
      this.$router.push({ path: '/jackpot' });
    },
    handleAviator() {
      this.$router.push({ name: 'aviator' });
    },
    handleLigi() {
      this.$router.push({ path: '/ligisoo' });
    },
    handleCasino() {
      this.$router.push({ path: '/casino' });
    },
    toggleExpansion(tournamentId) {
      this.expandedLeagues = {
        ...this.expandedLeagues,
        [tournamentId]: !this.expandedLeagues[tournamentId],
      };
    },
    formatDateTime(dateTimeString) {
      return dayjs(dateTimeString).format('DD/MM, HH:mm')
    },
    getStartTimeDisplay(dateTimeString) {
      const now = dayjs();
      const gameTime = dayjs(dateTimeString);
      const diffMinutes = gameTime.diff(now, 'minute');
      if (diffMinutes > 0 && diffMinutes <= 45) {
        return `Starts in ${diffMinutes}'${diffMinutes === 1 ? '' : ''}`;
      }
      return dayjs(dateTimeString).format('DD/MM, HH:mm');
    },
    isStartingSoon(dateTimeString) {
      const now = dayjs();
      const gameTime = dayjs(dateTimeString);
      const diffMinutes = gameTime.diff(now, 'minute');
      return diffMinutes > 0 && diffMinutes <= 45;
    },
    // handleScroll() {
    //   if (this.debounceTimer) {
    //     clearTimeout(this.debounceTimer);
    //   }
    //   return new Promise((resolve, reject) => {
    //     this.debounceTimer = setTimeout(async () => {
    //       const container = this.$refs.scrollContainer;
    //       if (
    //           container &&
    //           container.scrollTop + container.clientHeight >= container.scrollHeight - this.loaderDistance &&
    //           !this.isBusy
    //       ) {
    //         try {
    //           await this.fetchHighlights(this.selectedTab, false, true);
    //           resolve();
    //         } catch (error) {
    //           reject(error);
    //         }
    //       } else {
    //         resolve();
    //       }
    //     }, this.debounceTimeout);
    //   });
    // },

    handleScroll() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
      }

      const container = this.$refs.scrollContainer;
      if (
        container &&
        container.scrollTop + container.clientHeight >= container.scrollHeight - this.loaderDistance &&
        !this.isBusy
      ) {
        this.fetchHighlights(this.selectedTab, false, true)
      }
    },

    handleFilterSelected(filter) {
      this.isPopular = filter === 'popular'
      this.showDatepicker = filter === 'popular'

      if (this.isBusy) {
        return
      }
      const tabStore = useTabStore()
      tabStore.setSelectedTab(filter)
      this.resetParams()
      this.highlights = []
      this.matchIds.clear()
      this.currentPage = 0
      console.log("handleFilterSelected")
      this.isBusy = false
      this.fetchHighlights(filter, true)
      this.scrollToTop()
    },
    handleHoursUpdate(hours) {
      this.optionFilter = hours;
      this.currentPage = 0;
      console.log("handleHoursUpdate")
      this.hours=0
      this.resetParams();

      switch (this.optionFilter) {
        case '3':
        case '6':
        case '12':
          this.hours = parseInt(this.optionFilter);
          this.params.hours = this.hours;

          this.params.start = dayjs().format();
          this.params.end = dayjs().add(this.hours, 'hour').format();
          break;

        case 'countries':
          router.push('/countries');
          return;

        case 'highlights':
          this.isPopular = true;
          break;

        case 'today':
          this.params.date = dayjs().format('YYYY-MM-DD');
          useTabStore().setSelectedTab(this.optionFilter);
          this.params.today = 1;
          break;

        case 'tomorrow':
          this.params.date = dayjs().add(1, 'day').format('YYYY-MM-DD');
          useTabStore().setSelectedTab(this.optionFilter);
          this.params.tomorrow = 1;
          break;

        case 'two-days':
          this.params.start = dayjs().format('YYYY-MM-DD');
          this.params.end = dayjs().add(1, 'day').format('YYYY-MM-DD');
          break;

        case 'boosted':
          this.params.boosted = 1;
          break;

        case 'live':
          router.push('/live');
          return;

        default:
          this.fetchHighlights(this.selectedTab, true);  // Set resetHighlights flag to true
          break;
      }

      this.fetchHighlights(this.selectedTab, true);  // Set resetHighlights flag to true

    },
    handleDayUpdate(day) {
      this.date = day
      // this.currentPage = 0
      // console.log("handleDayUpdate")
      // this.fetchHighlights('date', true)  // Set resetHighlights flag to true
      // console.log("Other guy")
    },
    handleLive(){
      useTabStore().setCategoryId(0)
      this.resetParams()
      this.params.match_live_status = 1;
      this.currentPage = 0;
      console.log("handleLive")
      this.fetchHighlights(this.selectedTab, true)
    },
    handleDateUpdate(date) {
      this.date = dayjs(date).format('YYYY-MM-DD')
      this.currentPage = 0
      console.log("handleDateUpdate")
      this.fetchHighlights('date', true)  // Set resetHighlights flag to true
      this.scrollToTop()
    },
    handleCountryUpdate(country) {
      this.params.match_live_status = 0;
      const tabStore = useTabStore()
      tabStore.setCategoryId(country.category_id)
      this.params.category_id = country.category_id
      this.currentPage = 0
      // console.log("handleCountryUpdate")
      // this.fetchHighlights(this.selectedTab, true, true)  // Set resetHighlights flag to true
      // this.scrollToTop();
    },
    // setCategoryId(categoryId){
    //   if(Number(categoryId)){
    //     this.categoryId= categoryId
    //     this.currentPage = 0
    //     // console.log("setCategoryId :: " + categoryId)
    //     this.fetchHighlights(this.selectedTab, true)  // Set resetHighlights flag to true
    //     this.scrollToTop()
    //   }
    // },
    setCategoryId(categoryId) {
      if (categoryId !== null && categoryId !== undefined) {
        this.categoryId = categoryId;
        this.currentPage = 0;
        console.log("setCategoryId :: " + categoryId)
        this.fetchHighlightsImpli(this.selectedTab,true, true);
        this.scrollToTop();

      }
    },
    setTournamentId(tournamentId) {
      if(Number(tournamentId)){
        this.tourNamentId = tournamentId
        this.currentPage = 0
        console.log("setTournamentId")
        this.fetchHighlights(this.selectedTab, true)
      }

    },
    setHighlightMarketId(highlightMarketId) {
      if(Number(highlightMarketId)){
        this.highlightMarketId = highlightMarketId
        this.currentPage = 0
        console.log("setHighlightMarketId")
        this.fetchHighlights(this.selectedTab, true)  // Set resetHighlights flag to true
      }
    },
    handleFilterSelection(filter) {
      this.selectedFilter = filter;
    },

    setSelectedSportFromRoute(sportName) {
      this.selectedSport = sportName;
      const sportStore = useSportStore();
      const sportId = sportStore.sportsMap[sportName] || 1;
      sportStore.updateSportId(sportId);
    },

    resetParams() {
      this.params = { ...this.defaultParams }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    async fetchHighlightsImpli(filter, resetHighlights = false, isScroll = false) {
      let gamesFetched = 0;

      if ((this.isBusy || this.isLoadingMore) || (this.currentPage > 0 && this.currentPage >= this.lastPage) || !isMounted) {
        console.log("Found isBusy,isLoadingMore,currentPage,lastPage")
        return;
      }
      console.log("Filter: " + filter);
      console.log("Reset Highlights: " + resetHighlights);
      console.log("isScroll: " + isScroll);
      this.hasFetched = false;
      const savedPosition = sessionStorage.getItem('scrollPosition');
      // if (savedPosition !== null && savedPosition !== undefined){
      //   setTimeout(() => {
      //     window.scrollTo(0, parseInt(savedPosition, 10));
      //   }, 500);
      //   sessionStorage.removeItem('scrollPosition');
      // }

      if (!isScroll) {
        this.isBusy = true;
        // this.scrollToTop();
      } else {
        this.isLoadingMore = true;
      }
      this.error = null;

      if (resetHighlights) {
        console.log("Found reset highlights is true")
        this.highlights = [];
        this.tours = [];
        this.filteredHighlights = [];
        this.matchIds.clear();
        this.currentPage = 0;
      }

      const sportStore = useSportStore();
      const sportId = this.$route.params.sport_id || sportStore.sportId;
      const tabStore = useTabStore();
      const categoryId = tabStore.categoryId || this.params.category_id || 0;

      this.currentPage += 1;
      this.params.page = this.currentPage;
      this.params.sport_id = sportId;
      this.params.category_id = categoryId;
      this.params.tournament_id = this.tourNamentId;
      this.params.highlight_market_id = this.highlightMarketId;
      // console.log('We are here :: ' + this.currentPage)
      const today = dayjs().format('YYYY-MM-DD');
      const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
      const dayAfterTomorrow = dayjs().add(2, 'day').format('YYYY-MM-DD');

      switch (filter) {
        case 'popular':
          this.params = { ...this.params, today: 0, tomorrow: 0, upcoming: 0, hours: this.hours, per_page: 10 };
          break;
        case 'today':
          this.params = { ...this.params, start: today, end: today, today: 1, tomorrow: 0, upcoming: 0, hours: this.hours, per_page: 10 };
          break;
        case 'tomorrow':
          this.params = { ...this.params, start: tomorrow, end: tomorrow, today: 0, tomorrow: 1, upcoming: 0, per_page: 10 };
          break;
        case 'dayAfterTomorrow':
          this.params = { ...this.params, start: dayAfterTomorrow, end: dayAfterTomorrow, today: 0, tomorrow: 0, upcoming: 0, per_page: 10 };
          break;
        case 'date':
          if (this.date) {
            this.params = { ...this.params, start: this.date, end: this.date, today: 0, tomorrow: 0, upcoming: 0, per_page: 10 };
          }
          break;
        default:
          this.params = { ...this.params, start: this.date || today, end: this.date || today, today: this.date ? 0 : 1, tomorrow: 0, upcoming: 0, hours: this.hours };
      }

      const url = `/highlights/${sportId}`;

      if (this.selectedFilter === 'tournament') {
        this.params.order_type = 'priority';
      } else if (this.selectedFilter === 'time') {
        this.params.order_type = 'time';
      }

      try {
        const response = await fix.get(url, { params: this.params });
        const games = response.data.data || [];
        gamesFetched = games.length;

        if (resetHighlights) {
          this.highlights = games;
          this.matchIds = new Set(games.map(game => game.match_id));

          const groupByHour = games.reduce((acc, currentItem) => {
            const dateString = currentItem.date;
            const date = new Date(dateString);
            const hourValue = date.getHours();
            if (!acc[hourValue]) {
              acc[hourValue] = [];
            }
            acc[hourValue].push(currentItem);
            return acc;
          }, {});

          this.filteredHighlights = Object.entries(groupByHour).map(([hour, games]) => ({ hour, games }));
        } else {

          if(this.highlights === undefined){
            this.highlights = []

          }
          games.forEach(game => {
            if (!this.matchIds.has(game.match_id)) {
              this.highlights.push(game);
              this.matchIds.add(game.match_id);
            }
          });

          const groupByHour = this.highlights.reduce((acc, currentItem) => {
            const date = new Date(currentItem.date);
            const hourValue = date.getHours();
            if (!acc[hourValue]) {
              acc[hourValue] = [];
            }
            acc[hourValue].push(currentItem);
            return acc;
          }, {});

          Object.entries(groupByHour).forEach(([hour, games]) => {
            const hourNum = Number(hour);
            const existingIndex = this.filteredHighlights.findIndex(group => Number(group.hour) === hourNum);

            if (existingIndex !== -1) {
              // Update existing group
              const existingGroup = this.filteredHighlights[existingIndex];
              const newGameIds = new Set(games.map(game => game.match_id));
              existingGroup.games = existingGroup.games
                .filter(game => !newGameIds.has(game.match_id))
                .concat(games);
            } else {
              // Insert new group in the correct sorted position
              const newGroup = { hour: hour, games };
              let inserted = false;

              for (let i = 0; i < this.filteredHighlights.length; i++) {
                if (Number(this.filteredHighlights[i].hour) > hourNum) {
                  this.filteredHighlights.splice(i, 0, newGroup);
                  inserted = true;
                  break;
                }
              }

              if (!inserted) {
                this.filteredHighlights.push(newGroup); // Add to the end if no larger hour is found
              }
            }
          });
        }

        this.lastPage = response.data.last_page;
        if (response.data.total === 0) {
          this.highlights = [];
        }

      } catch (error) {
        this.error = 'Failed to fetch data';
        console.error('Fetch error:', error);
      } finally {
        this.hasFetched = true;
        if (!isScroll) {
          this.isBusy = false;
        } else {
          this.isLoadingMore = false;
        }

        // setTimeout(() => {
        //   window.addEventListener('scroll', this.handleScroll);
        // }, 500);
      }
      if (gamesFetched < 10 && this.currentPage < this.lastPage) {
        // console.log("I am fetching more games")
        await this.fetchHighlights(filter, false, isScroll);
      }

},

    fetchHighlights: _.debounce(function (...args) {
      return this.fetchHighlightsImpli.apply(this, args);
    }, 300),

    hasMoreGames() {
      return this.currentPage < this.lastPage
    },
    getTeamClass(alias) {
      if (alias === '1') return 'home-team'
      if (alias === 'X') return 'draw-team'
      if (alias === '2') return 'away-team'
      return ''
    },
    // fixtureUpdates(match_id, status) {
    //   const mid = match_id === 0 ? 1 : match_id
    //   const topic_betstop = `feeds/${status === 1 ? 'live' : 'prematch'}/bet_stop/${mid}`
    //   const topic_match = `feeds/${status === 1 ? 'live' : 'prematch'}/odds_change/${mid}`
    //   const topic_match_ended = `feeds/${status === 1 ? 'live' : 'prematch'}/match_ended/${mid}`
    //   client.removeAllListeners(topic_betstop)
    //   client.removeAllListeners(topic_match)
    //   client.removeAllListeners(topic_match_ended)
    //   client.on('connect', () => {
    //     client.subscribe(topic_betstop)
    //     client.subscribe(topic_match_ended)
    //     client.subscribe(topic_match)
    //   })
    //   client.on('message', (topic, msg) => {
    //     const payload = JSON.parse(msg.toString())
    //     this.uxUpdate(payload)
    //   })
    // },
    isSelectedOdd(match_id) {
      let selectedOdds = JSON.parse(localStorage.getItem('selectedOdds')) || [];
      return selectedOdds.some(item => item.match_id === match_id);
    },
    // processData() {
    //   this.highlights.forEach(game => {
    //     this.fixtureUpdates(game.match_id, game.producer_status)
    //   })
    // },
    profileUpdates: function () {
      let x = useAuthStore().user?.id.toString(25);
      const profileTopic = "topic/profile/" + x;

      client.on('connect', () => {
        client.subscribe(profileTopic)
      })
      client.on('message', (profileTopic, msg) => {
        const payload = JSON.parse(msg.toString())
        this.uxUpdate(payload)
      })
    },
  },
  async mounted() {

    const container = this.$refs.scrollContainer;

    if (container) {
      // container.addEventListener('scroll', this.handleScroll);
    }
    try {
      await this.fetchHighlights(this.selectedTab);
      // if (savedPosition !== null) {
      //   console.log("scrolling Y::" + savedPosition)
      //   window.scrollTo(0, parseInt(savedPosition, 10));
      //   sessionStorage.removeItem('scrollPosition')
      // }
    }
    finally {
      this.isBusy = false;
    }
    window.addEventListener('scroll', this.handleScroll);
    const fs = useFixtureStore().autoRefreshUI
    // this.processData()
    this.profileUpdates()
    isMounted.value = true;
    this.showDatepicker = this.selectedTab === 'popular'



  },
  computed: {
    sportStore() {
      return useSportStore()
    },

    loaderDistance() {
      let totalGames = 0;
      if (this.filteredHighlights && this.filteredHighlights.length) {
        totalGames = this.filteredHighlights.reduce((sum, group) => {
          return sum + (group.games ? group.games.length : 0);
        }, 0);
      }
      return totalGames < 10 ? 5 : 20;
    },

    selectedTab() {
      const tabStore = useTabStore()
      return tabStore.selectedTab
    },
    // categoryId() {
    //   const tabStore = useTabStore()
    //   return tabStore.categoryId
    // },
    tournamentId() {
      const tabStore = useTabStore()
      return tabStore.tournamentId
    },
    isAccountCreated() {
      return this.$route.name === 'accountcreated'
    },
    loadMore() {
      return !this.isBusy && this.highlights.length > 0 && this.hasMoreGames();
    },
  },
  beforeUnmount() {
    const container = this.$refs.scrollContainer;
    if (container) {
      container.removeEventListener('scroll', this.handleScroll);
    }
    window.removeEventListener('scroll', this.handleScroll);
    client.removeAllListeners()
    isMounted.value = false;
  },
  watch: {
    '$route.params.sport_name': {
      immediate: true,
      handler(newSport) {
        // console.log("Is it me?")
        // console.log('Sport nsme', newSport)
        if (newSport !== undefined && newSport !== null){
          this.currentPage = 0
          this.resetParams()
          this.setSelectedSportFromRoute(newSport);
          const tabStore = useTabStore()
          tabStore.setCategoryId(0)
          this.fetchHighlights(this.selectedTab, true)
          this.currentSport = newSport || '';
        }

      }
    },
    'params.category_id': {
      immediate: true,
      handler(newCategoryId) {
        if (!Number.isNaN(newCategoryId) && newCategoryId !== 0){
          console.log("Setting category ID: " + newCategoryId)
          this.setCategoryId(newCategoryId)
        }

      }
    },
    '$route.query.Country': {
      immediate: true,
      handler(newCategoryId) {
        const newCat = Number(newCategoryId);
        const tabStore = useTabStore()
        tabStore.setCategoryId(newCat)
        this.setCategoryId(newCat)
      }
    },
    '$route.query.tournamentId': {
      immediate: true,
      handler(newTournamentId) {
        this.setTournamentId(newTournamentId)
      }
    },
    selectedFilter(newFilter) {
      this.fetchHighlights(this.selectedTab, newFilter);
    }
  }
}
</script>

<style scoped>
.scrollable-container {
  height: 80vh; /* or any fixed height */
  overflow-y: auto;
}
.other-markets-options:hover {
  color: #8FAF37;
}
.selected-odd {
  color: red;
}
.red-text {
  color: red;
}

.no-markets-message-wrapper {
  text-align: center;
  padding: 20px;
}

.no-data-icon {
  margin-bottom: 10px;
}

.no-data-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.notif-btn {
  border: 1px solid var(--color-fg);
  color: var(--color-fg);
  font-weight: bold;
}

.button-group button {
  margin: 5px;
  padding: 8px 15px;
  border-radius: 5px;
}

@media only screen and (max-width: 450px) {
  .button-group button {
    margin: 3px;
    padding: 4px 8px;
    border-radius: 5px;
  }
}
</style>
