import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import { useAuthStore } from '@/stores/auth.js'
import { setActivePinia } from 'pinia'
import pinia from '@/stores/index.js'

const authGuard = (to, from, next) => {
  if(!pinia) return setActivePinia(pinia);
  const authStore = useAuthStore();
  if (authStore.user?.auth) {
    next()
  } else {
    localStorage.setItem('intendedRoute', to.fullPath);
    next({ name: 'login' })
  }
};

const preventauthGuard = (to, from, next) => {
  if(!pinia) return setActivePinia(pinia);
  const authStore = useAuthStore();
  if (authStore.user?.auth) {
    next({ name: 'home' });
  } else {
    next();
  }
};

const Login = () => import('../views/Auth/Login.vue')
const Register = () => import('../views/Auth/Register.vue')
const Forgot = () => import('../views/Auth/Forgot.vue')
const Reset = () => import('../views/Auth/Reset.vue')
const Verification = () => import('../views/Auth/Verification.vue')
const Live = () => import('../views/Live.vue')
const Esoccer = () => import('../views/ESoccer.vue')
const EPL = () => import('../views/EPL.vue')
const Europa = () => import('../views/Europa.vue')
const UCL = () => import('../views/UCL.vue')
const Casino = () => import('../views/Casino.vue')
const Casinogames = () => import('../views/Casinogames.vue')
const CasinoProvider = () => import('../views/CasinoProvider.vue')
const Crashgames = () => import('../views/CrashGames.vue')
const Aviator = () => import('../views/Aviator.vue')
// const AviatorDemo = () => import('../views/Aviator-demo.vue')
// const AviatorLanding = () => import('../views/Aviator-landing.vue')
// const AviatorBeta = () => import('../views/Aviator-beta.vue')
const Comet = () => import('../views/Comet.vue')
const JetX = () => import('../views/JetX.vue')
const Freebet = () => import('../views/Freebet.vue')
const Jackpot = () => import('../views/Jackpot/Jackpot.vue')
const JackpotResults = () => import('../views/Jackpot/JackpotResults.vue')
const JackpotSingleResults = () => import('../views/Jackpot/JackpotSingleResults.vue')
const Virtuals = () => import('../views/Virtuals.vue')
const VirtualGames = () => import('../views/VirtualGames.vue')
const AfconVirtuals = () => import('../Virtuals/AfconVirtuals.vue')
const Champions = () => import('../views/Champions.vue')
const CopaAmerica = () => import('../Virtuals/CopaAmerica.vue')
const EnglandVirtuals = () => import('../Virtuals/EnglandVirtuals.vue')
const Roulette = () => import('../Virtuals/Roulette.vue')
const EnglandOnDemand = () => import('../Virtuals/England0nDemand.vue')
const ItalyLeague = () => import('../Virtuals/ItalyLeague.vue')
const EuroVirtuals = () => import('../Virtuals/EuroVirtuals.vue')
const Keno = () => import('../Virtuals/Keno.vue')
const Numbers = () => import('../Virtuals/Numbers.vue')
const SpainLeague = () => import('../Virtuals/SpainLeague.vue')
const WorldCup = () => import('../Virtuals/AfconVirtuals.vue')
const Instant = () => import('../views/Instant.vue')
// const Logifuture = () => import('../views/Logifuture.vue')
const MSL = () => import('../views/MSL.vue')
const Spin2Win = () => import('../views/Spin2Win.vue')
const Booming = () => import('../views/Booming.vue')
const Pragmatic = () => import('../views/Pragmatic.vue')
const Aviatrix = () => import('../views/Aviatrix.vue')
const Livescore = () => import('../views/Livescore.vue')
const Limoon = () => import('../views/Limoon.vue')
const Imoon = () => import('../views/Imoon.vue')
const TableGames = () => import('../views/TableGames.vue')
const Poker = () => import('../views/Poker.vue')
const Search = () => import('../views/Search.vue')
const Bets = () => import('../views/Bets.vue')
const SingleBet = () => import('../views/SingleBet.vue')
const Terms = () => import('../views/Terms.vue')
const Policy = () => import('../views/Policy.vue')
const Referrals = () => import('../views/Referrals.vue')
const Responsible = () => import('../views/Responsible.vue')
const Profile = () => import('../views/Profile.vue')
const AccountCreated = () => import('../views/accountcreated.vue')
const BetSlip = () => import('../views/BetSlip.vue')
const BetSuccess = () => import('../views/BetPlaceSuccess.vue')
const Countries = () => import('../views/Countries.vue')
const SelfExclusion = () => import('../views/SelfExclusion.vue')
const Faqs = () => import('../views/Faqs.vue')
// const Sambaza = () => import('../views/SambazaStake/Sambaza.vue')
// const VerifyStake = () => import('../views/SambazaStake/Verify-stake.vue')
const Help = () => import('../views/Help.vue')
const AppPage = () => import('../views/AppPage.vue')
const Promos = () => import('../views/Promos.vue')
const Support = () => import('../views/Support.vue')
const Promotions = () => import('../views/Promotions.vue')
const LeaderBoard = () => import('../views/LeaderBoard.vue')
const MoreMarkets = () => import('../views/MoreMarkets.vue')
const Leagues = () => import('../views/Leagues.vue')
const Print = () => import('../views/Print.vue')
const PrintResults = () => import('../views/PrintResults.vue')
const JackpotTerms=()=> import('../views/Jackpot/JackpotTerms.vue')
const HowToPlay=()=>import('../views/Jackpot/HowToPlay.vue')
const LigiSoo = () => import('../views/LigiSoo.vue')
const SpinNWin = () => import('../views/SpinNWin.vue')
const CodeHub = ()=> import('../views/CodeHub.vue')

const routes = [
  {
    name: 'home',
    alias: '/index',
    path: '/',
    component: Home,
    meta: {
      title: 'MayBets | Best Online Sports Betting in Kenya. Join Now & Win Big!',
      metaTags: [
        { name: 'description', content: 'Join MayBets for the ultimate online betting experience. Bet on your favorite sports and games. Start winning big today!' },
        { name: 'keywords', content: 'Maybets' },
        { name: 'twitter:description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { property: 'og:description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
        { name: 'renderer', content: 'webkit' },
        { name: 'application-name', content: 'maybets.com' },
        { name: 'keywords', content: 'Maybets' },
        { name: 'application-name', content: 'MaybetsBettingApp' },
        { name: 'msapplication-TileColor', content: '#000000' },
        { name: 'theme-color', content: '#000000' },
        { property: 'og:image', content: 'https://storage.googleapis.com/maybet/public/android-chrome-512x512-up.png' },
        { 'property': 'og:image:secure_url', 'content': 'https://storage.googleapis.com/maybet/public/android-chrome-512x512-up.png' },
        { 'property': 'og:image:type', 'content': 'image/png' },
        { 'property': 'og:description', 'content': 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { 'property': 'og:title', 'content': 'MayBets | Best Online Sports Betting in Kenya. Join Now & Win Big!' },
        { 'property': 'fb:app_id', 'content': '118458264679039' },
        { 'property': 'og:url', 'content': 'https://maybets.com' },
        { 'property': 'og:site_name', 'content': 'maybets' },
        { 'property': 'og:type', 'content': 'website' },
        { 'property': 'twitter:site', 'content': '@MaybetsOfficial' },
        { 'name': 'twitter:card', 'content': 'summary_large_image' }
      ]
    }
  },
  {
    path: '/sport/:sport_name',
    name: 'sport',
    component: Home
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      hideFooter: true,
      title: 'MayBets Login - Secure and Easy Access',
      metaTags: [
        { name: 'description', content: 'Securely log in to your MayBets account. Manage your bets, view history, and enjoy seamless access. Log in and start betting now!' },
        { name: 'keywords', content: 'Maybets Login' },
      ]
    },
    beforeEnter: preventauthGuard
  },
  {
    name: 'signup',
    alias: '/signup',
    path: '/register',
    component: Register,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets Register' },
      ],
      hideFooter: true
    },
    beforeEnter: preventauthGuard
  },
  {
    name: 'forgot-password',
    path: '/forgot',
    component: Forgot,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true
    },
    beforeEnter: preventauthGuard
  },
  {
    name: 'reset-password',
    path: '/reset',
    component: Reset,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true
    },
    beforeEnter: preventauthGuard
  },
  {
    name: 'verify-code',
    path: '/verification',
    component: Verification,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true
    },
    beforeEnter: preventauthGuard
  },
  {
    name: 'live',
    path: '/live',
    component: Live,
    meta: {
      title: 'Live Betting at MayBets - Real-Time Thrills',
      metaTags: [
        { name: 'description', content: 'Enjoy real-time thrills with live betting at MayBets. Bet on live sports events and experience the excitement. Join the action now!' },
        { name: 'keywords', content: 'Maybets' },
      ]
    }
  },
  {
    name: 'esoccer',
    path: '/esoccer',
    component: Esoccer,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets' },
      ]
    }
  },
  {
    name: 'epl',
    path: '/epl',
    component: EPL
  },
  {
    name: 'ucl',
    path: '/ucl',
    component: UCL
  },
  {
    name: 'europa',
    path: '/europaleague',
    component: Europa
  },
  {
    name: 'casino',
    path: '/casino',
    component: Casino,
    meta: {
      title: 'MayBets Casino - Premium Online Casino Games',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true
    }
  },
  {
    name: 'casinoProvider',
    path: '/casino/:providerName',
    component: CasinoProvider,
    props: true,
  },
  {
    name: 'casinogames',
    path: '/casinogames',
    component: Casinogames,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'crashgames',
    path: '/crashgames',
    component: Crashgames,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    }
  },
  {
    name: 'aviator',
    path: '/aviator',
    component: Aviator,
  },
  // {
  //   name: 'aviatordemo',
  //   path: '/play-aviatordemo',
  //   component: AviatorDemo,
  // },
  // {
  //   name: 'aviatorlanding',
  //   path: '/play-aviator',
  //   component: AviatorLanding,
  // },
  // {
  //   name: 'aviator2',
  //   path: '/aviatormiles',
  //   component: AviatorBeta,
  //   meta: {
  //     title: 'Maybets | Best Online Sports Betting in Kenya',
  //     metaTags: [
  //       { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
  //       { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
  //     ],
  //   },
  // },
  {
    name: 'comet',
    path: '/comet',
    component: Comet,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'jetx',
    path: '/jetx',
    component: JetX,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    }
  },
  {
    name: 'App',
    path: '/app',
    component: AppPage,
    meta: { hideFooter: true }
  },
  {
    name: 'selfexclusioon',
    path: '/selfexclusion',
    component: SelfExclusion,
    beforeEnter: authGuard
  },
  // {
  //   name: 'sambaza',
  //   path: '/sambazastake',
  //   component: Sambaza,
  //   beforeEnter: authGuard
  // },
  // {
  //   name: 'verifystake',
  //   path: '/sambaza/verify',
  //   component: VerifyStake,
  //   beforeEnter: authGuard
  // },
  {
    name: 'jackpot',
    path: '/jackpot',
    component: Jackpot,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true
    }
  },
  {
    name: 'jackpot-results',
    path: '/jackpot/results',
    component: JackpotResults,
    meta: { hideFooter: true }
  },
  {
    name: 'jackpot-single-results',
    path: '/jackpot/result/:resultId',
    component: JackpotSingleResults,
    props: true,
    meta: { hideFooter: true }
  },
  {
    name: 'freebet',
    path: '/freebet',
    component: Freebet,
    meta: {
      title: 'Maybets Free Bet Bonus | Ksh 30 Free for New Players',
      metaTags: [
        { name: 'description', content: 'Maybets Free Bet: Get Ksh 30 free when you sign up. No deposit, no risk—just fun betting and real winnings. Start now!' },
        { name: 'keywords', content: 'Online betting bonuses, Free betting promotions Kenya, No-risk betting offers, Free play betting on mobile, New player betting rewards, How to claim free bets on Maybets, Free bet, Betting bonus, Maybets free bet, Risk-free betting, Betting in Kenya, Ksh 30 free bet offer for new players, Free bet bonus for beginners in Kenya, Maybets risk-free betting options, Free betting cash with Maybets' },
      ],
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'virtuals',
    path: '/virtualgames',
    component: Virtuals,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'virtualgames',
    path: '/virtuals',
    component: VirtualGames,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
    },
  },
  {
    name: 'afconvirtuals',
    path: '/afconvirtuals',
    component: AfconVirtuals,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'champions',
    path: '/champions',
    component: Champions,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'copaamerica',
    path: '/copaamericavirtuals',
    component: CopaAmerica,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'englandondemand',
    path: '/englandondemand',
    component: EnglandOnDemand,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'eurovirtuals',
    path: '/eurovirtuals',
    component: EuroVirtuals,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'englandleague',
    path: '/englandleague',
    component: EnglandVirtuals,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'italyleague',
    path: '/italyleague',
    component: ItalyLeague,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'keno',
    path: '/keno',
    component: Keno,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'worldcupvirtuals',
    path: '/worldcupvirtuals',
    component: WorldCup,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'spainleague',
    path: '/spainleague',
    component: SpainLeague,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'numbers',
    path: '/numbers',
    component: Numbers,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'roulette',
    path: '/roulette',
    component: Roulette,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'instant',
    path: '/instant',
    component: Instant,
    meta: {
      title: 'Maybets | Best Online Sports Betting in Kenya',
      metaTags: [
        { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
        { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
      ],
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  // {
  //   name: 'logifuture',
  //   path: '/logifuture',
  //   component: Logifuture,
  //   meta: {
  //     title: 'Maybets | Best Online Sports Betting in Kenya',
  //     metaTags: [
  //       { name: 'description', content: 'Maybets offers the best odds, 2MB APP, Best Aviator, fastest live betting, instant deposits/withdrawals, exciting casino games, Best Aviator. Register/Login to enjoy bonuses, Freebets, Cash backs, jackpots, crash games, virtual sports +More.' },
  //       { name: 'keywords', content: 'Maybets, Maybet, Betting Kenya, Freebet, Aviator, Best Betting Company in Africa, Best Betting Company in Kenya, Best Betting Company in East Africa, Maybet, Livescore, Bonus, Jackpot, 2MB app, Maybets PayBill' },
  //     ],
  //     hideFooter: true,
  //     hideHeader: true
  //   },
  //   beforeEnter: authGuard
  // },
  {
    name: 'search',
    path: '/search',
    component: Search
  },
  {
    name: 'msl',
    path: '/msl',
    component: MSL,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'spin2win',
    path: '/spin2win',
    component: Spin2Win,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'aviatrix',
    path: '/aviatrix',
    component: Aviatrix,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'livescore',
    path: '/livescore',
    component: Livescore,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'codehub',
    path: '/bethub',
    component: CodeHub,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'booming',
    path: '/BoomingGames',
    component: Booming,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'pragmatic',
    path: '/pragmatic',
    component: Pragmatic,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'limoon',
    path: '/test53551111111222',
    component: Limoon,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
    beforeEnter: authGuard
  },
  {
    name: 'imoon',
    path: '/imoon',
    component: Imoon,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'tablegames',
    path: '/tablegames',
    component: TableGames,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'poker',
    path: '/poker',
    component: Poker,
    meta: {
      hideFooter: true,
      hideHeader: true
    },
  },
  {
    name: 'bets',
    path: '/bets',
    component: Bets,
    beforeEnter: authGuard,
    props: (route) => ({ filter: route.query.filter, subfilter: route.query.subfilter })
  },
  // {
  //   name: 'bets',
  //   path: '/bets',
  //   component: Bets,
  //   beforeEnter: (to, from, next) => {
  //     if (!to.query.filter) {
  //       next({ name: 'bets', query: { filter: 'normalBets' } });
  //     } else {
  //       next();
  //     }
  //   },
  //   props: (route) => ({ filter: route.query.filter, subfilter: route.query.subfilter }),
  // },
  {
    path: '/b/:code',
    name: 'singlebet',
    component: SingleBet
  },
  {
    name: 'responsible',
    path: '/responsible-gambling',
    component: Responsible,
    meta: { hideFooter: true }
  },
  {
    name: 'terms',
    path: '/terms-and-condition',
    component: Terms,
    meta: { hideFooter: true }
  },
  {
    name: 'policy',
    path: '/privacy-policy',
    component: Policy,
    meta: { hideFooter: true }
  },
  {
    name: 'referrals',
    path: '/referrals',
    component: Referrals,
    meta: { hideFooter: true }
  },
  {
    name: 'help',
    path: '/how-to-play',
    component: Help,
    meta: { hideFooter: true }
  },
  {
    name: 'app',
    path: '/app',
    component: AppPage,
    meta: { hideFooter: true }

  },
  {
    name: 'promos',
    path: '/promos',
    component: Promos
  },
  {
    name: 'support',
    path: '/support',
    component: Support
  },
  {
    name: 'promotions-single',
    path: '/promotions/:slug',
    component: Promotions
  },
  {
    name: 'faqs',
    path: '/faqs',
    component: Faqs,
    meta: { hideFooter: true }
  },
  {
    name: 'country-route',
    path: '/countries',
    component: Countries
  },
  {
    name: 'league-route',
    path: '/leagues',
    component: Leagues
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    beforeEnter: authGuard
  },
  {
    name: 'accountcreated',
    path: '/accountcreated',
    component: AccountCreated,
    beforeEnter: authGuard
  },
  {
    name: 'success',
    path: '/success',
    component: BetSuccess,
    meta: { hideFooter: true }
  },
  {
    name: 'betslip',
    path: '/betslip',
    component: BetSlip,
    meta: { hideFooter: true }
  },
  {
    name: 'spinnwin',
    path: '/spinnwin',
    component: SpinNWin,
    meta: { hideFooter: true }
  },
  {
    name: 'leaderboard',
    path: '/referral',
    component: LeaderBoard,
    meta: { hideFooter: true }
  },
  {
    name: 'more-markets',
    path: '/mm/:game_id',
    component: MoreMarkets,
    meta: { hideFooter: true }
  },
  {
    name: 'more-markets-event',
    path: '/event/:country/:tournament/:event_name/:game_id',
    component: MoreMarkets,
    meta: { hideFooter: true }
  },
  {
    path: '/share/:id',
    name: 'share',
    redirect: (to) => {
      return { name: 'home', query: { id: to.params.id } }
    }
  },
  {
    name: 'print',
    path: '/print',
    component: Print,
    meta: { hideFooter: true }
  },
  {
    name: 'print-results',
    path: '/print-result',
    component: PrintResults,
    props: route => ({
      sport_id: route.query.sport_id,
      today: route.query.today,
      tomorrow: route.query.tomorrow,
      start: route.query.start,
      end: route.query.end,
      number_of_matches: route.query.number_of_matches
    }),
    meta: {
      hideFooter: true,
      hideHeader: true
    }
  },
  {
    name:'jackpot-terms',
    path:'/jackpot/terms',
    component:JackpotTerms,
    meta: { hideFooter: true }
  },
  {
    name:'how-to-play',
    path:'/jackpot/how-to-play',
    component:HowToPlay,
    meta: { hideFooter: true }
  },
  {
    name: 'ligi',
    path: '/ligisoo',
    component: LigiSoo,
    meta: { hideFooter: true }
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active-game',
  scrollBehavior(to, from, savedPosition) {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    // if(savedPosition){
    //   return savedPosition
    // }else{
    //   return { left: 0, top: 0, behavior: 'smooth' };
    // }
    if (savedPosition) {
      return savedPosition;
    }
    // if (to.hash) {
    //   return {
    //     el: to.hash,
    //     behavior: 'smooth'
    //   };
    // }
    return { left: 0, top: 0, behavior: 'smooth' };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = false;
  if (to.meta.requiresAuth && !isAuthenticated) {
    localStorage.setItem('intendedRoute', to.fullPath);
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router